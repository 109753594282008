import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e427d2fa = () => interopDefault(import('..\\_theme\\pages\\Category.vue' /* webpackChunkName: "_theme/pages/Category" */))
const _387397b1 = () => interopDefault(import('..\\_theme\\pages\\Checkout.vue' /* webpackChunkName: "" */))
const _09f21ca0 = () => interopDefault(import('..\\_theme\\pages\\Checkout\\Billing.vue' /* webpackChunkName: "" */))
const _cc93788a = () => interopDefault(import('..\\_theme\\pages\\Checkout\\Payment.vue' /* webpackChunkName: "" */))
const _b4dd0a6e = () => interopDefault(import('..\\_theme\\pages\\Checkout\\Shipping.vue' /* webpackChunkName: "" */))
const _e1c17320 = () => interopDefault(import('..\\_theme\\pages\\Checkout\\ThankYou.vue' /* webpackChunkName: "" */))
const _68997a6a = () => interopDefault(import('..\\_theme\\pages\\Checkout.vue' /* webpackChunkName: "_theme/pages/Checkout" */))
const _61ed8520 = () => interopDefault(import('..\\_theme\\pages\\Checkout\\Billing.vue' /* webpackChunkName: "_theme/pages/Checkout/Billing" */))
const _009cd72b = () => interopDefault(import('..\\_theme\\pages\\Checkout\\Payment.vue' /* webpackChunkName: "_theme/pages/Checkout/Payment" */))
const _1b7d5559 = () => interopDefault(import('..\\_theme\\pages\\Checkout\\Shipping.vue' /* webpackChunkName: "_theme/pages/Checkout/Shipping" */))
const _050b2100 = () => interopDefault(import('..\\_theme\\pages\\Checkout\\ThankYou.vue' /* webpackChunkName: "_theme/pages/Checkout/ThankYou" */))
const _7b9e2c4a = () => interopDefault(import('..\\_theme\\pages\\Home.vue' /* webpackChunkName: "" */))
const _0d591738 = () => interopDefault(import('..\\_theme\\pages\\Home.vue' /* webpackChunkName: "_theme/pages/Home" */))
const _346146a8 = () => interopDefault(import('..\\_theme\\pages\\MyAccount.vue' /* webpackChunkName: "_theme/pages/MyAccount" */))
const _9fbd4fda = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\BillingDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/BillingDetails" */))
const _082d8a4c = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\LoyaltyCard.vue' /* webpackChunkName: "_theme/pages/MyAccount/LoyaltyCard" */))
const _45c8135e = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\MyNewsletter.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyNewsletter" */))
const _a44ea77e = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\MyProfile.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyProfile" */))
const _1548e49a = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\MyReviews.vue' /* webpackChunkName: "_theme/pages/MyAccount/MyReviews" */))
const _8f3dd09c = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\OrderHistory.vue' /* webpackChunkName: "_theme/pages/MyAccount/OrderHistory" */))
const _03e51218 = () => interopDefault(import('..\\_theme\\pages\\MyAccount\\ShippingDetails.vue' /* webpackChunkName: "_theme/pages/MyAccount/ShippingDetails" */))
const _26a8287a = () => interopDefault(import('..\\_theme\\pages\\Product.vue' /* webpackChunkName: "_theme/pages/Product" */))
const _624888a2 = () => interopDefault(import('..\\_theme\\pages\\ResetPassword.vue' /* webpackChunkName: "" */))
const _640cf0d5 = () => interopDefault(import('..\\_theme\\pages\\ResetPassword.vue' /* webpackChunkName: "_theme/pages/ResetPassword" */))
const _0aa7292e = () => interopDefault(import('..\\_theme\\pages\\Category.vue' /* webpackChunkName: "" */))
const _1119a486 = () => interopDefault(import('..\\_theme\\pages\\MyAccount.vue' /* webpackChunkName: "" */))
const _5bb69ed4 = () => interopDefault(import('..\\_theme\\pages\\Product.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Category",
    component: _e427d2fa,
    name: "Category___en"
  }, {
    path: "/checkout",
    component: _387397b1,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _09f21ca0,
      name: "billing___en"
    }, {
      path: "payment",
      component: _cc93788a,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _b4dd0a6e,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _e1c17320,
      name: "thank-you___en"
    }]
  }, {
    path: "/checkout",
    component: _387397b1,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _09f21ca0,
      name: "billing___en"
    }, {
      path: "payment",
      component: _cc93788a,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _b4dd0a6e,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _e1c17320,
      name: "thank-you___en"
    }]
  }, {
    path: "/Checkout",
    component: _68997a6a,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _61ed8520,
      name: "Checkout-Billing___en"
    }, {
      path: "Payment",
      component: _009cd72b,
      name: "Checkout-Payment___en"
    }, {
      path: "Shipping",
      component: _1b7d5559,
      name: "Checkout-Shipping___en"
    }, {
      path: "ThankYou",
      component: _050b2100,
      name: "Checkout-ThankYou___en"
    }]
  }, {
    path: "/de",
    component: _7b9e2c4a,
    name: "home___de"
  }, {
    path: "/de",
    component: _7b9e2c4a,
    name: "home___de"
  }, {
    path: "/Home",
    component: _0d591738,
    name: "Home___en"
  }, {
    path: "/MyAccount",
    component: _346146a8,
    name: "MyAccount___en",
    children: [{
      path: "BillingDetails",
      component: _9fbd4fda,
      name: "MyAccount-BillingDetails___en"
    }, {
      path: "LoyaltyCard",
      component: _082d8a4c,
      name: "MyAccount-LoyaltyCard___en"
    }, {
      path: "MyNewsletter",
      component: _45c8135e,
      name: "MyAccount-MyNewsletter___en"
    }, {
      path: "MyProfile",
      component: _a44ea77e,
      name: "MyAccount-MyProfile___en"
    }, {
      path: "MyReviews",
      component: _1548e49a,
      name: "MyAccount-MyReviews___en"
    }, {
      path: "OrderHistory",
      component: _8f3dd09c,
      name: "MyAccount-OrderHistory___en"
    }, {
      path: "ShippingDetails",
      component: _03e51218,
      name: "MyAccount-ShippingDetails___en"
    }]
  }, {
    path: "/Product",
    component: _26a8287a,
    name: "Product___en"
  }, {
    path: "/reset-password",
    component: _624888a2,
    name: "reset-password___en"
  }, {
    path: "/reset-password",
    component: _624888a2,
    name: "reset-password___en"
  }, {
    path: "/ResetPassword",
    component: _640cf0d5,
    name: "ResetPassword___en"
  }, {
    path: "/de/Category",
    component: _e427d2fa,
    name: "Category___de"
  }, {
    path: "/de/checkout",
    component: _387397b1,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _09f21ca0,
      name: "billing___de"
    }, {
      path: "payment",
      component: _cc93788a,
      name: "payment___de"
    }, {
      path: "shipping",
      component: _b4dd0a6e,
      name: "shipping___de"
    }, {
      path: "thank-you",
      component: _e1c17320,
      name: "thank-you___de"
    }]
  }, {
    path: "/de/checkout",
    component: _387397b1,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _09f21ca0,
      name: "billing___de"
    }, {
      path: "payment",
      component: _cc93788a,
      name: "payment___de"
    }, {
      path: "shipping",
      component: _b4dd0a6e,
      name: "shipping___de"
    }, {
      path: "thank-you",
      component: _e1c17320,
      name: "thank-you___de"
    }]
  }, {
    path: "/de/Checkout",
    component: _68997a6a,
    name: "Checkout___de",
    children: [{
      path: "Billing",
      component: _61ed8520,
      name: "Checkout-Billing___de"
    }, {
      path: "Payment",
      component: _009cd72b,
      name: "Checkout-Payment___de"
    }, {
      path: "Shipping",
      component: _1b7d5559,
      name: "Checkout-Shipping___de"
    }, {
      path: "ThankYou",
      component: _050b2100,
      name: "Checkout-ThankYou___de"
    }]
  }, {
    path: "/de/Home",
    component: _0d591738,
    name: "Home___de"
  }, {
    path: "/de/MyAccount",
    component: _346146a8,
    name: "MyAccount___de",
    children: [{
      path: "BillingDetails",
      component: _9fbd4fda,
      name: "MyAccount-BillingDetails___de"
    }, {
      path: "LoyaltyCard",
      component: _082d8a4c,
      name: "MyAccount-LoyaltyCard___de"
    }, {
      path: "MyNewsletter",
      component: _45c8135e,
      name: "MyAccount-MyNewsletter___de"
    }, {
      path: "MyProfile",
      component: _a44ea77e,
      name: "MyAccount-MyProfile___de"
    }, {
      path: "MyReviews",
      component: _1548e49a,
      name: "MyAccount-MyReviews___de"
    }, {
      path: "OrderHistory",
      component: _8f3dd09c,
      name: "MyAccount-OrderHistory___de"
    }, {
      path: "ShippingDetails",
      component: _03e51218,
      name: "MyAccount-ShippingDetails___de"
    }]
  }, {
    path: "/de/Product",
    component: _26a8287a,
    name: "Product___de"
  }, {
    path: "/de/reset-password",
    component: _624888a2,
    name: "reset-password___de"
  }, {
    path: "/de/reset-password",
    component: _624888a2,
    name: "reset-password___de"
  }, {
    path: "/de/ResetPassword",
    component: _640cf0d5,
    name: "ResetPassword___de"
  }, {
    path: "/de/c/:slug_1",
    component: _0aa7292e,
    name: "category___de"
  }, {
    path: "/de/my-account/:pageName?",
    component: _1119a486,
    name: "my-account___de"
  }, {
    path: "/de/my-account/:pageName?",
    component: _1119a486,
    name: "my-account___de"
  }, {
    path: "/de/p/:id/:slug",
    component: _5bb69ed4,
    name: "product___de"
  }, {
    path: "/de/p/:id/:slug",
    component: _5bb69ed4,
    name: "product___de"
  }, {
    path: "/de/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _0aa7292e,
    name: "category___de"
  }, {
    path: "/c/:slug_1",
    component: _0aa7292e,
    name: "category___en"
  }, {
    path: "/my-account/:pageName?",
    component: _1119a486,
    name: "my-account___en"
  }, {
    path: "/my-account/:pageName?",
    component: _1119a486,
    name: "my-account___en"
  }, {
    path: "/p/:id/:slug",
    component: _5bb69ed4,
    name: "product___en"
  }, {
    path: "/p/:id/:slug",
    component: _5bb69ed4,
    name: "product___en"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _0aa7292e,
    name: "category___en"
  }, {
    path: "/",
    component: _7b9e2c4a,
    name: "home___en"
  }, {
    path: "/",
    component: _7b9e2c4a,
    name: "home___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
